import {AfterViewInit, Component, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {UserListItem} from '../../../models/userListItem';

@Component({
  selector: 'app-new-review-cycle',
  templateUrl: './new-review-cycle.component.html',
  styleUrls: ['./new-review-cycle.component.scss']
})
export class NewReviewCycleComponent implements AfterViewInit {

  public readonly newReviewCycleForm: FormGroup;

  newReviewCycle: any;
  advancedOptionsPannel = false;
  managers: UserListItem[];

  constructor(private readonly formBuilder: FormBuilder, private readonly apiService: ApiService, private dialog: MatDialogRef<NewReviewCycleComponent>) {
    this.newReviewCycleForm = this.formBuilder.group({
      name: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      compileReviewsByRCManager: [true, Validators.required],
      ratingsFromFeedbacks: [false, Validators.required],
      finalRatingRatio: [50, Validators.required],
      feedbackApproval: [false, Validators.required],
      approveFeedbacksByRCManager: [true, Validators.required],
      feedbacksCanHangRC: [false, Validators.required],
      manager: ['', Validators.required],
    });
  }

  createReviewCycle() {
    if (this.newReviewCycleForm.valid) {
      this.newReviewCycle = this.newReviewCycleForm.value;
      console.log(this.newReviewCycle);
      this.apiService.postReviewCycle(this.newReviewCycle).subscribe(result => {
        this.dialog.close({data:result});
      });
    }
  }

  formatRatingRatioLabel(value: number) {
    return value + '%';
  }

  ngAfterViewInit(): void {
    this.apiService.getManagers().subscribe(result => {
      this.managers = result;
      console.log(result);
    });
  }

}
