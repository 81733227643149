import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-expansion-overview',
  templateUrl: './expansion-overview.component.html',
  styleUrls: ['./expansion-overview.component.scss']
})
export class ExpansionOverviewComponent implements OnInit {
  panelOpenState = false;

  @Input()
  title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
