import {FeedbackListItem} from './feedbackListItem';

export class ReviewListItem {

  id: string;
  position: number;

  revieweeId: string;
  revieweeName: string;

  authorName: string;
  workProfileName: string;

  reviewCycleId: string;
  reviewCycleName: string;

  feedbacks: FeedbackListItem[];
  feedbacksWithRatings: boolean;

  expand = false; // used to add new reviewers to reviewee
  status: string;
}
