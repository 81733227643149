import { Component, OnInit } from '@angular/core';
import {TreeNode} from 'primeng/api';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  data1: TreeNode[];
  selectedNode: TreeNode;

  constructor(private apiService: ApiService) {

    this.apiService.getHierarcy().subscribe( result => {
      this.data1 = result;
    });

    this.data1 = [{
      label: 'CEO',
      type: 'person',
      styleClass: 'p-person',
      expanded: true,
      data: {name:'Walter White', 'avatar': 'users/walter.jpg'},
      children: [
        {
          label: 'CFO',
          type: 'person',
          styleClass: 'p-person',
          expanded: true,
          data: {name:'Saul Goodman', 'avatar': 'users/saul.jpg'},
          children:[{
            label: 'Tax',
            styleClass: 'department-cfo'
          },
            {
              label: 'Legal',
              styleClass: 'department-cfo'
            }],
        },
        {
          label: 'COO',
          type: 'person',
          styleClass: 'p-person',
          expanded: true,
          data: {name:'Mike E.', 'avatar': 'users/mike.jpg'},
          children:[{
            label: 'Operations',
            styleClass: 'department-coo'
          }]
        },
        {
          label: 'CTO',
          type: 'person',
          styleClass: 'p-person',
          expanded: true,
          data: {name:'Jesse Pinkman', 'avatar': 'users/jesse.jpg'},
          children:[{
            label: 'Development',
            styleClass: 'department-cto',
            expanded: true,
            children:[{
              label: 'Analysis',
              styleClass: 'department-cto'
            },
              {
                label: 'Front End',
                styleClass: 'department-cto'
              },
              {
                label: 'Back End',
                styleClass: 'department-cto'
              }]
          },
            {
              label: 'QA',
              styleClass: 'department-cto'
            },
            {
              label: 'R&D',
              styleClass: 'department-cto'
            }]
        }
      ]
    }];

  }

  ngOnInit(): void {
  }

  onNodeSelect(event) {
    console.log(event.node.label);
  }

}
