<h3>Work Profiles</h3>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="creation_date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
    <td mat-cell *matCellDef="let element"> {{element.creation_date}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
