import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {WpListItem} from '../../models/wpListItem';

@Component({
  selector: 'app-work-profiles',
  templateUrl: './work-profiles.component.html',
  styleUrls: ['./work-profiles.component.scss']
})
export class WorkProfilesComponent implements OnInit, AfterViewInit {

  workProfiles: WpListItem[] = [];
  displayedColumns: string[] = ['name', 'creation_date'];
  dataSource;

  constructor(private readonly apiService: ApiService) {
  }

  getWorkProfiles() {
    this.apiService.getWorkProfiles().subscribe(result => {
      this.workProfiles = result;

      this.dataSource = new MatTableDataSource(this.workProfiles);
      this.dataSource.sort = this.sort;
    });
  }

  ngOnInit(): void {
  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.getWorkProfiles();
  }

}
