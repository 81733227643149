<h3>Feedback from {{feedbackInfo.reviewerName}} for {{feedbackInfo.revieweeName}} in {{feedbackInfo.reviewCycleName}}</h3>
<p>Ratings are {{feedbackContent?.withRatings}}</p>

<div *ngFor="let workProfileField of feedbackContent?.workProfileFields; index as i">
  <p-fieldset legend=" {{workProfileField.title}}" [toggleable]="false">
    <p>{{feedbackContent?.fields[i]}}
      <br>
      <span *ngIf="">Rating: {{feedbackContent.ratings[i]}} | </span>Category: {{workProfileField.category}}
    </p>
  </p-fieldset>
  <br><br>
</div>

