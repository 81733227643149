import { Component, OnInit } from '@angular/core';
import {FeedbackListItem} from '../../models/feedbackListItem';
import {ApiService} from '../../services/api.service';
import {MatDialog} from '@angular/material/dialog';
import {NewFeedbackComponent} from '../new-feedback/new-feedback.component';

@Component({
  selector: 'app-feedback-requests',
  templateUrl: './feedback-requests.component.html',
  styleUrls: ['./feedback-requests.component.scss']
})
export class FeedbackRequestsComponent implements OnInit {

  feedbackRequests: FeedbackListItem[] = [];
  feedbackRequestsColumns: string[] = ['position', 'fullName', 'name', 'endDate', 'status', 'action'];

  constructor(private apiService: ApiService, public dialog: MatDialog) {
  }


  newFeedback(feedback: any) {
    let NewFeedbackDialog = this.dialog.open(NewFeedbackComponent, {
      data: feedback,
      width:'1600px',
      maxHeight: '96vh'
    });

    NewFeedbackDialog.afterClosed().subscribe(result => {
      if (result) {
        // remove feedback from list after submission.
        this.feedbackRequests = this.feedbackRequests.filter(value => value.id !== result.data.id);
      }
    });
  }

  ngOnInit(): void {
    this.apiService.getFeedbackRequests().subscribe(feedbackRequests => {
      this.feedbackRequests = feedbackRequests;
      this.feedbackRequests.forEach((value, index) => {
        value.position = index + 1;
      });
    });
  }
}
