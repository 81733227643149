<p-organizationChart [value]="data1" selectionMode="single" [(selection)]="selectedNode" (onNodeSelect)="onNodeSelect($event)"
                     styleClass="company">
  <ng-template let-node pTemplate="person">
    <div class="node-header p-corner-top">{{node.label}}</div>
    <div class="node-content">
      <img src="../../../assets/images/{{node.data.avatar}}" width="64">
      <div>{{node.data.name}}</div>
    </div>
  </ng-template>
  <ng-template let-node pTemplate="department">
    {{node.label}}
  </ng-template>
</p-organizationChart>


<!--<app-employees></app-employees>-->
<br><br><br>

