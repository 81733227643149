<mat-card>
<button mat-stroked-button color="primary" (click)="newReviewCycle()">New Review Cycle</button>
<br>
<br>

<mat-accordion>
  <mat-expansion-panel (opened)="reviewCycle.expand = true"
                       (closed)="reviewCycle.expand = false"
                       [expanded]="reviewCycle.expand" *ngFor="let reviewCycle of reviewCycles;">
    <mat-expansion-panel-header>
      <mat-panel-title>
       <div *ngIf="reviewCycle.status !=='CLOSED'"><i class="pi pi-spin pi-spinner" style="font-size: 1.2rem; margin-right: 20px"></i>{{reviewCycle.name}}</div>
       <div *ngIf="reviewCycle.status ==='CLOSED'"><i class="pi pi-check" style="font-size: 1.2rem; margin-right: 20px"></i>{{reviewCycle.name}}</div>
      </mat-panel-title>
      <mat-panel-description class="right-aligned">
        <span *ngIf="reviewCycle.expand"></span>
        <span *ngIf="!reviewCycle.expand">
          <!--{{reviewCycle.startDate | date: 'longDate' }} - {{reviewCycle.endDate | date: 'longDate' }} | {{reviewCycle.reviews.length}} participants | Status: {{reviewCycle.status}}-->
          <p-avatarGroup label="P">
            <p-avatar image="../../../assets/images/users/1.png" shape="circle"></p-avatar>
            <p-avatar image="../../../assets/images/vadim_negru.png" shape="circle"></p-avatar>
            <p-avatar label="JD" shape="circle"></p-avatar>
            <p-avatar image="../../../assets/images/users/2.png" shape="circle"></p-avatar>
            <p-avatar image="../../../assets/images/users/3.png" shape="circle"></p-avatar>
            <p-avatar image="../../../assets/images/users/4.png" pBadge value="4" severity="danger"
                      shape="circle"></p-avatar>
            <p-avatar label="+2" shape="circle"></p-avatar>
          </p-avatarGroup>
        </span>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="table">
      <div class="tr">
        <div class="td">
          <i class="pi pi-info-circle" style="font-size: 2rem"></i>
          <div>Status: {{reviewCycle.status}}</div>
          <div *ngIf="reviewCycle.status !== 'CLOSED'">Days remaining: {{reviewCycle.daysRemaining}}</div>
          <div>Managed by: <strong>{{reviewCycle.managerName}}</strong></div>
          <div *ngIf="!reviewCycle.compileReviewsByRCManager">Reviews are being compiled by the reviewee's manager.
          </div>
        </div>
        <div class="td">
          <i class="pi pi-users" style="font-size: 2rem"></i>
          <div>Participants: {{reviewCycle.reviews.length}}</div>
          <div>
            <button mat-button color="primary" (click)="manageReviewCycle(reviewCycle)">View Participants</button>
          </div>
          <br>
          <div>
            <br>
            Completed feedbacks: {{reviewCycle.completedFeedbacks}}
            <mat-progress-bar mode="determinate" value="{{reviewCycle.completedFeedbacks / reviewCycle.totalFeedbacks * 100}}"></mat-progress-bar>
          </div>
          <div *ngIf="reviewCycle.feedbackApproval">
            <br>
            Feedbacks needing approval: {{reviewCycle.feedbacksNeedingApproval}}
            <mat-progress-bar *ngIf="reviewCycle.feedbacksNeedingApproval > 0" mode="indeterminate"></mat-progress-bar>
            <mat-progress-bar *ngIf="reviewCycle.feedbacksNeedingApproval == 0" mode="determinate" value="100"></mat-progress-bar>
          </div>
          <div>
            <br>
            Reviews awaiting compilation: {{reviewCycle.reviewsAwaitingCompilation}}
            <mat-progress-bar *ngIf="reviewCycle.reviewsAwaitingCompilation > 0" mode="indeterminate"></mat-progress-bar>
            <mat-progress-bar *ngIf="reviewCycle.reviewsAwaitingCompilation == 0" mode="determinate" value="100"></mat-progress-bar>
          </div>
          <div>
            <br>
            Completed reviews: {{reviewCycle.completedReviews}}
            <mat-progress-bar mode="determinate" value="{{reviewCycle.completedReviews / reviewCycle.reviews.length * 100}}"></mat-progress-bar>
          </div>
        </div>
        <div class="td">
          <i class="pi pi-comments" style="font-size: 2rem"></i>
          <div *ngIf="!reviewCycle.ratingsFromFeedbacks">Feedback ratings are disabled.</div>
          <div *ngIf="reviewCycle.ratingsFromFeedbacks">Feedback ratings are enabled.</div>
          <div *ngIf="reviewCycle.ratingsFromFeedbacks">Final rating ratio: {{reviewCycle.finalRatingRatio}}%</div>
          <div *ngIf="!reviewCycle.feedbackApproval">Feedback approvals are disabled.</div>
          <div *ngIf="reviewCycle.feedbackApproval && !reviewCycle.approveFeedbacksByRCManager">Feedbacks are approved
            by the reviewee's manager.
          </div>
          <div *ngIf="reviewCycle.feedbackApproval && reviewCycle.approveFeedbacksByRCManager">Feedback approvals are
            enabled.
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td">

        </div>
        <div class="td">
          <div
            *ngIf="reviewCycle.feedbacksCanHangRC && reviewCycle.status !=='CLOSED'">! Unsubmitted feedbacks can prevent
            the review cycle from closing.
          </div>
        </div>
        <div class="td">

        </div>
      </div>
    </div>

    <hr>
    <div>
      <div *ngFor="let review of reviewCycle.reviews">
        {{review.revieweeName}} - {{review.workProfileName}} - {{review.status}} - {{review.feedbacks?
        review.feedbacks.length : 'no'}} feedbacks
        <button (click)="viewReview(review)" *ngIf="review.status === 'completed'" mat-button color="primary">view
          review
        </button>
        <button (click)="compileReview(review)"
                *ngIf="canCompileReview(review)" mat-button
                color="primary">compile review
        </button>

      </div>
    </div>
    <br>
    <!--<div class="participants">-->
    <!--<p *ngFor="let participant of participants">-->
    <!--{{participant.review.status}}-->
    <!--</p>-->
    <!--</div>-->
    <div class="button-row">
      <button mat-stroked-button color="warn" *ngIf="reviewCycle.status === 'OVERDUE'">Force Close</button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
</mat-card>

