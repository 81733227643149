<h3>Employees</h3>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
    <td mat-cell *matCellDef="let element"> {{element.number}} </td>
  </ng-container>

  <ng-container matColumnDef="fullName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
  </ng-container>

  <ng-container matColumnDef="workProfileName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Work Profile</th>
    <td mat-cell *matCellDef="let element"> {{element.workProfileName}} </td>
  </ng-container>

  <ng-container matColumnDef="managerName">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Manager Name </th>
  <td mat-cell *matCellDef="let element"> {{element.managerName}} </td>
  </ng-container>

  <!--<ng-container matColumnDef="status">-->
  <!--<th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>-->
  <!--<td mat-cell *matCellDef="let element"> {{element.status}} </td>-->
  <!--</ng-container>-->

  <ng-container matColumnDef="role">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Assigned Role </th>
  <td mat-cell *matCellDef="let element"> {{element.role}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
