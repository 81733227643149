import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './routes/dashboard/dashboard.component';
import {PageNotFoundComponent} from './routes/page-not-found/page-not-found.component';
import {ReviewCyclesComponent} from './routes/review-cycles/review-cycles.component';
import {UsersComponent} from './routes/users/users.component';
import {AuthGuard} from './services/auth.guard';
import {AppComponent} from './app.component';
import {WorkProfilesComponent} from './routes/work-profiles/work-profiles.component';

const routes: Routes = [
  {path: '', component: AppComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'reviewCycles', component: ReviewCyclesComponent, canActivate: [AuthGuard]},
  {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
  {path: 'workProfiles', component: WorkProfilesComponent, canActivate: [AuthGuard]},
  // {path: '', redirectTo: '/heroes-list', pathMatch: 'full'},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
