<mat-card class="dashboard-card">
  <mat-card-header>
    <mat-card-title *ngIf="myReviews.length">You have been reviewed in the following</mat-card-title>
    <mat-card-title *ngIf="!myReviews.length">Your reviews</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div *ngIf="myReviews.length">
      <table mat-table [dataSource]="myReviews" class="mat-elevation-z8">

        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> No.</th>
          <td mat-cell *matCellDef="let element"> {{element.position}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="authorName">
          <th mat-header-cell *matHeaderCellDef>Reviewer</th>
          <td mat-cell *matCellDef="let element"> {{element.authorName}}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="workProfileName">
          <th mat-header-cell *matHeaderCellDef>Work Profile</th>
          <td mat-cell *matCellDef="let element"> {{element.workProfileName}}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="reviewCycleName">
          <th mat-header-cell *matHeaderCellDef>Review Cycle</th>
          <td mat-cell *matCellDef="let element"> {{element.reviewCycleName}}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="feedbacks">
          <th mat-header-cell *matHeaderCellDef>Feedbacks</th>
          <td mat-cell *matCellDef="let element"> {{element.feedbacks.length}}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-stroked-button color="accent" (click)="viewReview(element)">View Review</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="myReviewsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: myReviewsColumns;"></tr>
      </table>
    </div>

    <div *ngIf="!myReviews.length">
      You have not been reviewed so far.
    </div>
  </mat-card-content>
  <!--<mat-card-actions>-->
    <!--<button mat-button>LIKE</button>-->
    <!--<button mat-button>SHARE</button>-->
  <!--</mat-card-actions>-->
</mat-card>

