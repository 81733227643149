import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from '../../services/api.service';
import {Feedback} from '../../models/Feedback';
import {FeedbackListItem} from '../../models/feedbackListItem';

@Component({
  selector: 'app-view-feedback',
  templateUrl: './view-feedback.component.html',
  styleUrls: ['./view-feedback.component.scss']
})
export class ViewFeedbackComponent implements OnInit {

  feedbackInfo: FeedbackListItem;
  feedbackContent: Feedback;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private readonly apiService: ApiService) {
    this.feedbackInfo = data;
    this.apiService.getFeedback(data.id).subscribe(result => {
      this.feedbackContent = result;
    });
  }

  ngOnInit(): void {

  }

}
