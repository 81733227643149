import {Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../services/api.service';
import {Review} from '../../models/Review';
import {ReviewListItem} from '../../models/ReviewListItem';
import {Feedback} from '../../models/Feedback';
import {MatInput} from '@angular/material/input';

@Component({
  selector: 'app-compile-review',
  templateUrl: './compile-review.component.html',
  styleUrls: ['./compile-review.component.scss']
})
export class CompileReviewComponent implements OnInit {

  review = new Review();
  reviewInfo: ReviewListItem;
  feedbacks: Feedback[];

  @ViewChildren(MatInput) input!: QueryList<MatInput>;

  focusInput(workProfileItem: any, index): void {
    workProfileItem.expand = true;

    let ind = 0;
    this.input.forEach(value => {
      if (index == ind) {
        setTimeout(() => {
          value.focus();
        }, 400);
      }
      ind++;
    });
  }

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private readonly apiService: ApiService, public dialog: MatDialogRef<CompileReviewComponent>) {
    dialog.disableClose = true;

    this.reviewInfo = data.review;

    this.apiService.getReview(this.reviewInfo.id).subscribe(review => {
      this.review = review;
      this.apiService.getFeedbacksForReview(this.reviewInfo.id).subscribe(feedbacks => {
        this.feedbacks = feedbacks;
      });
    }, error1 => {
      console.warn('user does not have permission to compile this review.');
      this.dialog.close({data: this.reviewInfo});
    });
  }

  ngOnInit(): void {
  }

  submitReview(status: string) {
    // TODO make ratings mandatory.
    this.review.status = status;

    this.apiService.postReview(this.review).subscribe(() => {
      this.reviewInfo.status = status;
      if (status == 'SUBMITTED')
        this.reviewInfo.status = 'completed';
      if (status == 'DRAFT')
        this.reviewInfo.status = 'compiling';
      this.dialog.close({data: this.reviewInfo});
    });
  }
}
