import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../services/api.service';
import {Feedback} from '../../models/Feedback';

@Component({
  selector: 'app-new-feedback',
  templateUrl: './new-feedback.component.html',
  styleUrls: ['./new-feedback.component.scss']
})
export class NewFeedbackComponent implements OnInit {

  feedback: Feedback;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private readonly apiService: ApiService, private dialog: MatDialogRef<NewFeedbackComponent>) {
    this.feedback = data;
  }

  newFeedback() {
    this.apiService.postFeedback(this.feedback).subscribe(result => {
      this.dialog.close({data: result});
    });
  }

  ngOnInit(): void {
  }

}
