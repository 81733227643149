import {AfterViewInit, Component, OnInit, Output} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EventEmitter} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {NgwWowService} from 'ngx-wow';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  loginException = '';
  appVersion = '2.0.1';

  @Output() loginEvent = new EventEmitter();

  public readonly loginForm: FormGroup;

  wowSubscription: Subscription;

  constructor(private readonly formBuilder: FormBuilder, private apiService: ApiService, private router: Router, private route: ActivatedRoute, private authenticationService: AuthenticationService, private wowService: NgwWowService) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.wowService.init();
  }

  async login() {
    await this.authenticationService.login(this.loginForm.get('username').value, this.loginForm.get('password').value);

    this.loginEvent.emit('logged in!');
    this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/dashboard');
  }

  ngOnInit(): void {
    if (localStorage.getItem(AuthenticationService.currentUserKey)) {
      console.log('user is authenticated, redirecting to dashboard');
      console.log('user is ' + this.authenticationService.loggedInUser.fullName);
      this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/dashboard');
    }

    // you can subscribe to WOW observable to react when an element is revealed
    this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item:HTMLElement) => {
        // do whatever you want with revealed element
      });
  }

}
