<app-landing *ngIf="!getUserDetails()" (loginEvent)="getUserDetails()"></app-landing>

<!-- Toolbar -->
<div class="toolbar" role="banner" *ngIf="getUserDetails()">
  <div class="logoDiv" (click)="goToDashboard()">
    <img src="https://fontmeme.com/permalink/171206/4a3694d27c926db0fde05efcae2002c3.png" class="logoWord1">
    <img src="https://fontmeme.com/permalink/171206/1b4a564ff29d5765e8a63e25284ba28a.png" class="logoWord2" >
  </div>
  <!--<span>Welcome {{getUserDetails().fullName}}</span>-->
  <div class="spacer"></div>
  <nav mat-tab-nav-bar>
    <a mat-tab-link
       *ngFor="let link of navLinks"
       [routerLink]="link.link"
       routerLinkActive #rla="routerLinkActive"
       [active]="rla.isActive" [class.active]="rla.isActive">
      {{link.label}}
    </a>
    <a mat-tab-link (click)="logout()" class="logout">Logout <img src="../assets/icons/logout.svg"></a>
  </nav>
</div>

<div class="content" role="main" *ngIf="getUserDetails()" >
  <router-outlet></router-outlet>
</div>
