import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ReviewListItem} from '../models/ReviewListItem';
import {Review} from '../models/Review';

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient) {

  }

  public getReviewCycles(): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/reviewCycles', {withCredentials: true});
  }

  public postReviewCycle(newReviewCycle: any): Observable<any> {
    return this.httpClient.post(environment.backendUrl + '/reviewCycles', newReviewCycle, {withCredentials: true});
  }

  public getReviewCycleParticipants(id: string): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/reviewCycles/' + id + '/reviews/feedbacks', {withCredentials: true});
  }

  public getFeedbackRequests(): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/feedback/requests', {withCredentials: true});
  }

  public getFeedback(id: string): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/feedback/' + id, {withCredentials: true});
  }

  public getFeedbacksForReview(id: string): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/reviews/' + id + '/feedbacks', {withCredentials: true});
  }

  public postFeedback(feedback: any): Observable<any> {
    return this.httpClient.patch(environment.backendUrl + '/feedback', feedback, {withCredentials: true});
  }

  public getReview(id: string): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/reviews/' + id, {withCredentials: true});
  }

  public getUserReviews(id: string): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/users/' + id + '/reviews', {withCredentials: true});
  }

  public postReview(review: Review): Observable<any> {
    return this.httpClient.patch(environment.backendUrl + '/reviews', review, {withCredentials: true});
  }

  public getEmployeeList(): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/users/employees', {withCredentials: true});
  }

  public getManagers(): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/users/managers', {withCredentials: true});
  }

  public getHierarcy(): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/users/hierarchy', {withCredentials: true});
  }

  public getWorkProfiles(): Observable<any> {
    return this.httpClient.get(environment.backendUrl + '/workProfiles', {withCredentials: true});
  }

  public login(username, password) {

    const headers = new HttpHeaders({
      authorization: 'Basic ' + btoa(username + ':' + password)
    });

    return this.httpClient.get(environment.backendUrl + '/login', {headers, withCredentials: true});
  }

  public logout() {
    return this.httpClient.post(environment.backendUrl + '/logout', null, {withCredentials: true});
  }

  public addReviewerToReviewee(reviewerId: string, review: ReviewListItem) {
    return this.httpClient.post(environment.backendUrl + '/feedback', {
      reviewerId: reviewerId,
      revieweeId: review.revieweeId,
      reviewCycleId: review.reviewCycleId
    }, {withCredentials: true});
  }
}
