import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {UserListItem} from '../../../models/userListItem';
import {ApiService} from '../../../services/api.service';
import {ReviewListItem} from '../../../models/ReviewListItem';
import {FeedbackListItem, FeedbackStatus} from '../../../models/feedbackListItem';
import {ViewFeedbackComponent} from '../../../components/view-feedback/view-feedback.component';
import {ViewReviewComponent} from '../../../components/view-review/view-review.component';
import {CompileReviewComponent} from '../../../components/compile-review/compile-review.component';

@Component({
  selector: 'app-manage-review-cycle',
  templateUrl: './manage-review-cycle.component.html',
  styleUrls: ['./manage-review-cycle.component.scss']
})
export class ManageReviewCycleComponent implements OnInit {

  reviewCycle: any;
  reviews: ReviewListItem[];

  newRevieweeForm = new FormControl();
  newReviewees: UserListItem[] = [];
  newRevieweesFilteredList: Observable<UserListItem[]>;

  private newReviewerForm = new FormControl();
  newReviewers: UserListItem[] = [];
  newReviewersFilteredList: Observable<UserListItem[]>;

  feedbackStatus = FeedbackStatus.status;

  // reviews: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private readonly apiService: ApiService, public dialog: MatDialog) {
    this.reviewCycle = data.reviewCycle;
    this.reviews = data.participants;
  }

  viewFeedback(feedback: FeedbackListItem) {
    this.dialog.open(ViewFeedbackComponent, {
      data: feedback,
      width: '1500px',
      maxHeight: '95vh'
    });

  }

  displayFn(user: UserListItem): string {
    return user && user.fullName ? user.fullName : '';
  }

  private filterReviewees(fullName: string): UserListItem[] {
    const filterValue = fullName.toLowerCase();

    return this.newReviewees.filter(option => option.fullName.toLowerCase().indexOf(filterValue) === 0);
  }

  private filterReviewers(fullName: string): UserListItem[] {
    const filterValue = fullName.toLowerCase();

    return this.newReviewers.filter(option => option.fullName.toLowerCase().indexOf(filterValue) === 0);
  }

  addReviewerToReviewee(index: number, reviewerId: string, review: ReviewListItem) {

    this.apiService.addReviewerToReviewee(reviewerId, review).subscribe((result: any) => {
      this.reviews[index].feedbacks.push(result);
      this.newReviewerForm.setValue('');
      if (review.status == 'ready to compile')
        review.status = 'feedback in progress';
    });
  }

  addNewReviewee() {
    // const newParticipant = new RcParticipantListItem();
    const review = new ReviewListItem();
    review.revieweeId = this.newRevieweeForm.value.id;
    review.revieweeName = this.newRevieweeForm.value.fullName;
    review.reviewCycleId = this.reviewCycle.id;
    review.reviewCycleName = this.reviewCycle.name;

    review.feedbacks = [];
    review.expand = true;

    this.reviews.push(review);

    this.newRevieweeForm.setValue('');
  }

  viewReview(review: ReviewListItem) {
    this.dialog.open(ViewReviewComponent, {
      data: {
        review: review
      },
      width: '1500px',
      maxHeight: '95vh'
    });
  }

  compileReview(review: ReviewListItem) {
    this.dialog.open(CompileReviewComponent, {
      data: {
        review: review
      },
      width: '1000px'
    });
  }

  ngOnInit(): void {
    this.apiService.getEmployeeList().subscribe(result => {

      this.newReviewees = result;
      this.newReviewers = result;

      this.newReviewees.forEach((value, index) => {
        value.number = index + 1;
      });

      this.newReviewers.forEach((value, index) => {
        value.number = index + 1;
      });
    });

    this.newRevieweesFilteredList = this.newRevieweeForm.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.fullName),
        map(fullName => fullName ? this.filterReviewees(fullName) : this.newReviewees.slice())
      );

    this.newReviewersFilteredList = this.newReviewerForm.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.fullName),
        map(fullName => fullName ? this.filterReviewers(fullName) : this.newReviewers.slice())
      );
  }

}
