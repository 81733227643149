<h3>{{reviewCycle.name}}</h3>

<mat-accordion>
  <mat-expansion-panel (opened)="review.expand = true"
                       (closed)="review.expand = false"
                       [expanded]="review.expand"
                       *ngFor="let review of reviews; index as i">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{review.revieweeName}}
      </mat-panel-title>
      <mat-panel-description *ngIf="!review.expand">
        {{review.feedbacks.length}} reviewers - {{review.status}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngFor="let feedback of review.feedbacks">
      <span>{{feedback.reviewerName}}</span>
      <span
        *ngIf="feedback.status === feedbackStatus.ASSIGNED.toString() || feedback.status === feedbackStatus.CREATED.toString()  || feedback.status === feedbackStatus.DRAFT.toString()">
        <button mat-button disabled>feedback not submitted</button></span>
      <span
        *ngIf="(feedback.status === feedbackStatus.SUBMITTED.toString() && !reviewCycle.feedbackApproval) || feedback.status === feedbackStatus.APPROVED.toString() || feedback.status === feedbackStatus.CLOSED.toString()">
        <button mat-button color="primary" (click)="viewFeedback(feedback)">view feedback</button></span>
      <span *ngIf="feedback.status === feedbackStatus.REJECTED.toString()"><button mat-button color="warn">feedback rejected</button></span>
    </div>

    <br>
    <form class="new-reviewer-form" *ngIf="review.status !== 'completed' && reviewCycle.status !== 'OVERDUE'">
      <mat-form-field>

        <mat-label>Add new reviewer</mat-label>
        <input type="text" matInput [formControl]="newReviewerForm" [matAutocomplete]="auto" name="newReviewer"
               placeholder="John Doe">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of newReviewersFilteredList | async" [value]="option">
            {{option.fullName}}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>
      <div class="button-row">
        <button mat-stroked-button color="primary"
                (click)="addReviewerToReviewee(i, this.newReviewerForm._pendingValue.id, review)">Add reviewer
        </button>
        <!--<button mat-stroked-button color="warn" (click)="participant.expand = false">Cancel</button>-->
      </div>
    </form>
    <div class="button-row right">
      <button (click)="viewReview(review)" *ngIf="review.status === 'completed'" mat-stroked-button color="primary">View review</button>
      <button (click)="compileReview(review)" *ngIf="review.status === 'ready to compile'" mat-stroked-button color="primary">Compile review</button>
    </div>

  </mat-expansion-panel>
</mat-accordion>

<br>

<form *ngIf="reviewCycle.status === 'CREATED' || reviewCycle.status ==='OPEN'">
  <mat-form-field>

    <mat-label>Add new reviewee</mat-label>
    <input type="text" matInput [formControl]="newRevieweeForm" [matAutocomplete]="auto" name="newReviewee"
           placeholder="John Doe">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of newRevieweesFilteredList | async" [value]="option">
        {{option.fullName}}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>
  <div class="button-row">
    <button mat-stroked-button color="primary" (click)="addNewReviewee()">Add reviewee</button>
    <button mat-stroked-button color="warn" [mat-dialog-close]="true">Cancel</button>
  </div>
</form>
