import {UserListItem} from '../models/userListItem';
import {ApiService} from './api.service';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class AuthenticationService {

  public static currentUserKey = 'lastAuthTime';

  constructor(private apiService: ApiService, private readonly router: Router) {

  }

  async login(username: string, password: string) {
    return new Promise(resolve => {
      this.apiService.login(username, password).subscribe((result: UserListItem) => {
        result.lastAuthTime = new Date();
        localStorage.setItem(AuthenticationService.currentUserKey, JSON.stringify(result));
        resolve(localStorage.getItem(AuthenticationService.currentUserKey));
      });
    });
  }

  async logout() {
    return new Promise(resolve => {
      this.apiService.logout().subscribe(() => {
          localStorage.removeItem(AuthenticationService.currentUserKey);
          resolve('logged out!');
        }
      );
    });
  }

  refreshUserSession(){
    const currentUserData = localStorage.getItem(AuthenticationService.currentUserKey);
    if (currentUserData) {
      const currentUser = JSON.parse(currentUserData);
      currentUser.lastAuthTime = new Date();
      localStorage.setItem(AuthenticationService.currentUserKey, JSON.stringify(currentUser));
    }
  }


  get loggedInUser(): UserListItem {
    const currentUserData = localStorage.getItem(AuthenticationService.currentUserKey);
    if (!currentUserData)
      // this.router.navigateByUrl('/');
      return null;
    else {
      const currentUser = JSON.parse(currentUserData);
      const time = new Date().valueOf() - new Date(currentUser.lastAuthTime).valueOf();
      if (time > 29 * 60000){
        console.warn('token age is ' + time) + ' and it has expired.';
        localStorage.removeItem(AuthenticationService.currentUserKey);
        // this.router.navigateByUrl('/');
        return null;
      }
      else return currentUser;
    }
  }
}
