import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-your-performance',
  templateUrl: './your-performance.component.html',
  styleUrls: ['./your-performance.component.scss']
})
export class YourPerformanceComponent implements OnInit {

  categoryRatingsAveraged: any[];

  constructor() { }

  ngOnInit(): void {
  }

}
