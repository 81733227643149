import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {RcListItem} from '../../models/rcListItem';
import {ManageReviewCycleComponent} from './manage-review-cycle/manage-review-cycle.component';
import {MatDialog} from '@angular/material/dialog';
import {ReviewListItem} from '../../models/ReviewListItem';
import {NewReviewCycleComponent} from './new-review-cycle/new-review-cycle.component';
import {RcParticipantListItem} from '../../models/rcParticipantListItem';
import {ViewReviewComponent} from '../../components/view-review/view-review.component';
import {CompileReviewComponent} from '../../components/compile-review/compile-review.component';

@Component({
  selector: 'app-review-cycles',
  templateUrl: './review-cycles.component.html',
  styleUrls: ['./review-cycles.component.scss']
})
export class ReviewCyclesComponent implements OnInit {

  reviewCycles: RcListItem[] = [];
  participants: ReviewListItem[];

  constructor(private readonly apiService: ApiService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.apiService.getReviewCycles().subscribe(result => {
      this.reviewCycles = result;
      this.reviewCycles[0].expand = true;
    });
  }

  newReviewCycle() {
    let newReviewCycleDialog = this.dialog.open(NewReviewCycleComponent, {
      width: '550px'
    });

    newReviewCycleDialog.afterClosed().subscribe(result => {
      if (result) {
        let newReviewCycle = new RcListItem();
        newReviewCycle = result.data;
        newReviewCycle.expand = true;

        this.reviewCycles.unshift(newReviewCycle);
      }
    });
  }

  manageReviewCycle(reviewCycle: RcListItem) {
    this.apiService.getReviewCycleParticipants(reviewCycle.id).subscribe(participants => {
      this.participants = participants;
      this.dialog.open(ManageReviewCycleComponent, {
        data: {
          reviewCycle: reviewCycle,
          participants: participants
        },
        width: '600px'
      });
    });
  }

  viewReview(review: ReviewListItem) {
    this.dialog.open(ViewReviewComponent, {
      data: {
        review: review
      },
      width: '1500px',
      maxHeight: '95vh'
    });
  }

  compileReview(review: ReviewListItem) {
    this.dialog.open(CompileReviewComponent, {
      data: {
        review: review
      },
      width: '1000px'
    });
  }

  canCompileReview(review: ReviewListItem): boolean {
    return review.status === 'ready to compile' || review.status === 'compiling';
  }

  canViewReview(review: ReviewListItem): boolean {
    return false;
  }
}
