<mat-card class="dashboard-card">
  <mat-card-header>
    <!--<div mat-card-avatar class="example-header-image"></div>-->
    <mat-card-title>Feedback requests</mat-card-title>
    <!--<mat-card-subtitle>Dog Breed</mat-card-subtitle>-->
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="feedbackRequests.length">
      <h4>Feedback requests</h4>
      <table mat-table [dataSource]="feedbackRequests" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> No.</th>
          <td mat-cell *matCellDef="let element"> {{element.position}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef>Reviewee</th>
          <td mat-cell *matCellDef="let element"> {{element.revieweeName}}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Review Cycle</th>
          <td mat-cell *matCellDef="let element"> {{element.reviewCycleName}}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>Due Date</th>
          <td mat-cell *matCellDef="let element"> {{element.dueDate | date: 'mediumDate'}}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element"> {{element.status}}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-stroked-button color="accent" (click)="newFeedback(element)">Give Feedback</button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="feedbackRequestsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: feedbackRequestsColumns;"></tr>
      </table>
    </div>
    <div *ngIf="!feedbackRequests.length">
      There are currently no feedback requests.
    </div>
  </mat-card-content>
  <!--<mat-card-actions>-->
    <!--<button mat-button>LIKE</button>-->
    <!--<button mat-button>SHARE</button>-->
  <!--</mat-card-actions>-->
</mat-card>
