<div id="top"></div>

<div class="landing-background">
  <div class="overlay">
    <div class="container">
      <div class="row">
        <div class="col-md-7">

          <!-- logo -->
          <div class="logoDiv wow fadeInDown">
            <img src="https://fontmeme.com/permalink/171206/4a3694d27c926db0fde05efcae2002c3.png" alt="logo"
                 id="logoWord1" style="width: 60%; height: 53%; vertical-align: bottom; margin-right: -5%;">
            <img src="https://fontmeme.com/permalink/171206/1b4a564ff29d5765e8a63e25284ba28a.png" alt="logo"
                 id="logoWord2" style="width: 25%; height: 21%; ">
          </div>
          <br>

          <h1 class="wow fadeInLeft" style="margin-top: 0px; padding: 15px;">
            <p style="padding: 0 0 0em;">360&deg; employee performance tracking</p>
          </h1>
        </div>

        <!-- login form -->
        <div class="col-md-5" style="margin-top: -20px;">
          <div class="signup-header wow fadeInUp">
            <h3 class="form-title text-center"></h3>
            <form class="form-header" role="form">

              <!-- login form -->
              <ng-container class="form-header" [formGroup]="this.loginForm">
                <div class="form-group">
                  <input formControlName="username" class="form-control input-lg" name="username" type="text"
                         placeholder="Email address" required>
                </div>
                <div class="form-group">
                  <input formControlName="password" class="form-control input-lg" name="password" type="password"
                         placeholder="Password" required>
                </div>
                <div class="form-group last">
                  <input type="submit" class="btn btn-primary btn-lg" (click)="login()"
                         value="Secure Sign In">
                </div>
              </ng-container>
              <div class="form-group" *ngIf="loginException">
                <div class="alert alert-dismissible alert-warning">
                  <button type="button" class="close" data-dismiss="alert">&times;</button>
                  <h5>{{loginException}}, please try
                    again.</h5>
                </div>
              </div>

              <p class="privacy text-center">Don't have an account yet? <a
                href="#contact">Get in touch</a> with us for a personalized offer.</p>
            </form>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="landing-text wow fadeInUp">
          <p style="text-align: center; 	text-shadow: 0 1px 2px rgba(0, 0, 0, 3.6);">With AI Natural
            Language Processing for automatic reviewer sentiment analysis</p>
        </div>

        <div class="head-btn wow fadeInLeft" style="text-align: center">
          <a href="#feature" class="btn-primary">features</a>
          <a href="#demo" class="btn-default">demo</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- NAVIGATION -->
<div class="menu-sticky">
  <nav class="navbar-wrapper navbar-default" role="navigation">
    <div class="container">
      <div class="navbar-header">
        <a class="navbar-brand site-name" href="#top"><img
          src="https://fontmeme.com/permalink/171206/4a3694d27c926db0fde05efcae2002c3.png" alt="logo"
          style="width: 200px; height: 40px; margin-top: -12px"
        ><img src="https://fontmeme.com/permalink/171206/1b4a564ff29d5765e8a63e25284ba28a.png" alt="logo"
              style="width: 80px; height: 16px; margin-bottom: -12px; margin-left: -13px;"></a>
      </div>

      <div id="navbar-scroll" class="collapse navbar-collapse navbar-review.center navbar-right">
        <ul class="nav navbar-nav">
          <li><a href="#intro">About</a></li>
          <li><a href="#feature">Features</a></li>
          <li><a href="#demo">Demo</a></li>
          <li><a href="#package">AI</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
</div>

<!-- intro section -->
<div id="intro" class="content">
  <div class="container">
    <div class="row">

      <!-- /.intro image -->
      <div class="col-md-6 intro-pic wow slideInLeft" style="margin-top: 2px">
        <img src="../../../assets/images/intro-image.jpg" alt="image" class="img-responsive">
      </div>

      <!-- /.intro content -->
      <div class="col-md-6 wow slideInRight">
        <h2>Making full use of technology</h2>
        <p>Any feedback data can be compiled and evaluated hands-on by any assigned manager, or handed to a <a
          href="#package">powerful AI</a> for
          automatic evaluation.
        </p>

        <div class="btn-section"><a href="#feature" class="btn-default">learn more</a></div>

      </div>
    </div>
  </div>
</div>

<!-- feature section -->
<div id="feature" class="content">
  <div class="container">
    <div class="row">
      <div class="col-md-10 col-md-offset-1 col-sm-12 text-center feature-title">

        <!-- /.feature title -->
        <h2>Tailored for your needs</h2>
        <p>Designed with medium-sized companies in mind, it uses the latest tech trends to help everybody
          improve. </p>
      </div>
    </div>
    <div class="col-md-12">
      <!-- /.feature 3 -->
      <div class="col-sm-6 feat-list">
        <i class="pe-7s-science pe-5x pe-va wow fadeInUp" data-wow-delay="0.4s"></i>
        <div class="inner">
          <h4>Sentiment Analysis</h4>
          <p>All the granular aspects you are interested in will be automagically compiled and delivered to
            you as they were
            already refined by experienced field professionals.</p>
        </div>
      </div>

      <!-- /.feature 4 -->
      <div class="col-sm-6 feat-list">
        <i class="pe-7s-users pe-5x pe-va wow fadeInUp" data-wow-delay="0.6s"></i>
        <div class="inner">
          <h4>Individual Performance</h4>
          <p>Get the best out of everyone! We collate big amounts of data in a simple
            and transparent manner, so the the core performance aspect of your company can be easily derived
            from
            individual performance.</p>
        </div>
      </div>
      <!-- /.feature 1 -->
      <div class="col-sm-6 feat-list">
        <i class="pe-7s-notebook pe-5x pe-va wow fadeInUp"></i>
        <div class="inner">
          <h4>Great data insight</h4>
          <p>Content builds relationships. Relationships are built on trust. Trust drives revenue.</p>
          <p>Based on your content, we will show you exactly what you need to focus on.
          </p>
        </div>
      </div>

      <!-- /.feature 2 -->
      <div class="col-sm-6 feat-list">
        <i class="pe-7s-tools pe-5x pe-va wow fadeInUp" data-wow-delay="0.2s"></i>
        <div class="inner">
          <h4>Fully customizable</h4>
          <p>Everything is fully customisable in terms of creating and managing work profiles and review
            cycles. The configurations are done based on individual user access levels.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /.demo section -->
<div id="demo">
  <div class="landing-background">
    <div class="overlay">
      <div class="container">
        <div class="col-md-8 col-md-offset-2 col-sm-12 text-center">

          <!-- /.demo title -->
          <h2 class="wow fadeInRight" style="padding-top: 50px; margin-top: 0px;">Try it out yourself right
            now!</h2>
          <p class="download-text wow fadeInLeft">We want you to see what we're talking about. <br>Log in with
            username <b><i>demo</i></b> and password <b><i>demo</i></b> to try out all the avaliable
            features, with full access roles.
            <br>The demo account is being reset periodically and is avaliable for everyone to explore.
          </p>
          <!-- /.download button -->
          <div class="download-cta wow fadeInLeft" style="padding-bottom: 70px;">
            <a href="#top" class="btn-default">try the demo account</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- AI section -->
<div id="package" class="content">
  <div class="container">
    <div class="text-center" style="padding-bottom: 40px;">
      <!-- /.pricing title -->
      <h2 class="wow fadeInLeft">Artificial Intelligence and people</h2>
      <div class="title-line wow fadeInRight" style="margin-top: 35px;"></div>
    </div>
    <!-- /.feature content -->
    <div class="col-md-6 wow fadeInLeft">
      <p>From smart home automations to streamlining daily work, artificial intelligence has made its way into our
        daily lives!<br><br>
        Here in the <span class="highlight">review</span> .<span class="highlight">center</span>, AI is used in
        the process of automating your entire review cycle workflow.
        It is constantly learning its user behaviour from writing feedbacks, compiling feedbacks in final
        reviews and from rating individual categories.
        <br><br>
        So, in no time, all the data you are interested in will be compiled and delivered to you without the
        need for human hands-on assessment.
      </p>


      <div class="btn-section"><a href="#feature" class="btn-primary">more features</a></div>

    </div>

    <!-- /.feature image -->
    <div class="col-md-6 feature-2-pic wow fadeInRight" style="padding-bottom: 40px;">
      <img src="../../../assets/images/feature2-image.jpg" class="img-responsive">
    </div>
  </div>
</div>

<!-- /.contact section -->
<div id="contact">
  <div class="landing-background">
    <div class="overlay">
      <div class="container">
        <div class="row contact-row">

          <!-- /.address and contact -->
          <div class="col-sm-5 contact-left wow fadeInUp">
            <h2><span class="highlight">Get</span> in touch</h2>

            <ul class="ul-address">
              <li>Use this form to contact us for a personalized setup for your company! We'll make sure
                that once you sign up, you are already up and running, without having to go trough
                complicated initial configurations.
              </li>
              <br>
              <li><i class="pe-7s-map-marker"></i>Bucharest, Romania</li>
              <li><i class="pe-7s-mail"></i><a href="mailto:office@review.center">office@review.center</a>
              </li>
              <li><i class="pe-7s-look"></i><a href="http://review.center">review.center</a></li>
            </ul>

          </div>

          <!-- /.contact form -->
          <div class="col-sm-7 contact-right">
            <form method="POST" id="contact-form" class="form-horizontal" action="contact" role="form"
                  onSubmit="gtag_report_conversion()">
              <div class="form-group">
                <input type="text" name="name" id="name" class="form-control wow fadeInUp"
                       placeholder="Company / Personal Name" required/>
              </div>
              <div class="form-group">
                <input type="text" name="email" id="email" class="form-control wow fadeInUp"
                       placeholder="Company / Personal Email" required/>
              </div>
              <div class="form-group">
                                <textarea name="message" rows="20" cols="20" id="message"
                                          class="form-control input-message wow fadeInUp" placeholder="Your Message"
                                          required></textarea>
              </div>
              <div class="form-group">
                <input type="submit" name="submit" value="submit" class="btn-primary btn-lg wow fadeInUp"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /.footer -->
<footer class="content">
  <div class="container" style="display: flex">
    <div class="social text-center" style="width: 50%; text-align: left;">
      <a class="wow fadeInUp" href="https://calatean.ro/"><i class="fa fa-power-off"></i></a>
      <a class="wow fadeInUp" href="https://gitlab.com/stephzone/" style="padding-left: 10px;"><i
        class="fa fa-github"></i></a>
    </div>
    <div style="width: 50%; text-align: right;">version {{appVersion}}</div>
    <a href="#" class="scrollToTop"><i class="pe-7s-up-arrow pe-va"></i></a>
  </div>
</footer>

