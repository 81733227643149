import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardComponent} from './routes/dashboard/dashboard.component';
import {LandingComponent} from './routes/landing/landing.component';
import {PageNotFoundComponent} from './routes/page-not-found/page-not-found.component';
import {ApiService} from './services/api.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbdTableSortableModule} from './components/table-sortable/table-sortable.module';
import {ReviewCyclesComponent} from './routes/review-cycles/review-cycles.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {ExpansionOverviewModule} from './components/expansion-overview/expansion-overview.module';
import {NewReviewCycleComponent} from './routes/review-cycles/new-review-cycle/new-review-cycle.component';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSliderModule} from '@angular/material/slider';
import {UsersComponent} from './routes/users/users.component';
import {WorkProfilesComponent} from './routes/work-profiles/work-profiles.component';
import {EmployeesComponent} from './routes/users/employees/employees.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import {ManageReviewCycleComponent} from './routes/review-cycles/manage-review-cycle/manage-review-cycle.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {NewFeedbackComponent} from './components/new-feedback/new-feedback.component';
import {ViewFeedbackComponent} from './components/view-feedback/view-feedback.component';
import {ViewReviewComponent} from './components/view-review/view-review.component';
import {CompileReviewComponent} from './components/compile-review/compile-review.component';
import {MyReviewsComponent} from './components/my-reviews/my-reviews.component';
import {StarRatingModule} from 'angular-star-rating';
import {AuthenticationService} from './services/authentication.service';
import {AuthGuard} from './services/auth.guard';
import {FeedbackRequestsComponent} from './components/feedback-requests/feedback-requests.component';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {FieldsetModule} from 'primeng/fieldset';
import {AvatarGroup, AvatarGroupModule} from 'primeng/avatargroup';
import {AvatarModule} from 'primeng/avatar';
import {NgwWowModule} from 'ngx-wow';
import {MatCardModule} from '@angular/material/card';
import {YourPerformanceComponent} from './components/your-performance/your-performance.component';
import {TokenInterceptor} from './services/token.interceptor';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LandingComponent,
    PageNotFoundComponent,
    ReviewCyclesComponent,
    NewReviewCycleComponent,
    ManageReviewCycleComponent,
    UsersComponent,
    WorkProfilesComponent,
    EmployeesComponent,
    NewFeedbackComponent,
    ViewFeedbackComponent,
    ViewReviewComponent,
    CompileReviewComponent,
    MyReviewsComponent,
    FeedbackRequestsComponent,
    YourPerformanceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbdTableSortableModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatTabsModule,
    ExpansionOverviewModule, // accordion as component
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatExpansionModule,
    MatRadioModule,
    MatTooltipModule,
    MatSliderModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatAutocompleteModule,
    StarRatingModule.forRoot(),
    OrganizationChartModule,
    FieldsetModule,
    AvatarGroupModule,
    AvatarModule,
    NgwWowModule,
    MatCardModule,
    MatProgressBarModule
  ],
  providers: [
    ApiService,
    AuthGuard,
    AuthenticationService,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
