import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../services/api.service';
import {ReviewListItem} from '../../models/ReviewListItem';
import {Review} from '../../models/Review';

@Component({
  selector: 'app-view-review',
  templateUrl: './view-review.component.html',
  styleUrls: ['./view-review.component.scss']
})
export class ViewReviewComponent implements OnInit {

  review: Review = new Review();
  reviewInfo: ReviewListItem;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private readonly apiService: ApiService, public dialog: MatDialog) {
    this.reviewInfo = data.review;

    this.apiService.getReview(this.reviewInfo.id).subscribe(review => {
      this.review = review;

      this.review.workProfileFields = this.review.workProfileFields.filter(field => field != '');
    });
  }

  ngOnInit(): void {
  }

}
