<h3 class="title">How did {{feedback.revieweeName}} do in {{feedback.reviewCycleName}}?</h3>
<p-avatar class="reviewee-avatar" image="../../../assets/images/users/1.png" pBadge value="4" severity="danger" size="xlarge"></p-avatar>

<!--<p>Ratings are {{feedback.}}</p>-->
<br>
<form class="form">
  <div *ngFor="let workProfileItem of feedback.workProfileFields; index as i;">
    <mat-form-field class="full-width">
      <mat-label>{{workProfileItem.title}}</mat-label>
      <textarea matInput matTextareaAutosize placeholder="Ex. It makes me feel..." [(ngModel)]="feedback.fields[i]"
                name="feedbackField{{i}}"></textarea>

    </mat-form-field>
    <star-rating *ngIf="feedback.withRatings" [starType]="'svg'" [hoverEnabled]="true" (starClickChange)="feedback.ratings[i] = $event.rating" [rating]="feedback.ratings[i]"></star-rating>
  </div>
  <div class="button-row">
    <button mat-raised-button color="primary" (click)="newFeedback()">Submit</button>
    <button mat-raised-button color="accent" [mat-dialog-close]="null">Cancel</button>
  </div>
</form>


