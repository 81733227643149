import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from './services/api.service';
import {Router} from '@angular/router';
import {UserListItem} from './models/userListItem';
import {AuthenticationService} from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'review-center-frontend';

  navLinks: any[];
  activeLinkIndex = -1;

  constructor(private readonly apiService: ApiService, private readonly router: Router, private authenticationService: AuthenticationService) {

    this.navLinks = [
      {
        label: 'Review Cycles',
        link: './reviewCycles',
        index: 0
      }, {
        label: 'Users',
        link: './users',
        index: 1
      }, {
        label: 'Work Profiles',
        link: './workProfiles',
        index: 2
      }
    ];
  }

  async logout() {
    await this.authenticationService.logout();
    this.router.navigateByUrl('/');
  }

  getUserDetails(): UserListItem{
    return this.authenticationService.loggedInUser;
  }

  goToDashboard(){
    this.router.navigateByUrl('/dashboard');
  }

  ngOnInit() {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
  }
}
