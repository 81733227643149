<h3 class="title">Compile review for {{reviewInfo.revieweeName}}</h3>
<p-avatar class="reviewee-avatar" image="../../../assets/images/users/1.png" pBadge value="4" severity="danger" size="xlarge"></p-avatar>

<form class="form">
  <mat-accordion class="full-width">
    <mat-expansion-panel (opened)="focusInput(workProfileItem, i)"
                         (closed)="workProfileItem.expand = false"
                         [expanded]="workProfileItem.expand" *ngFor="let workProfileItem of review.workProfileFields; index as i;" class="full-width">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{workProfileItem.title}}
        </mat-panel-title>
        <mat-panel-description class="right-aligned">
          <span *ngIf="workProfileItem.expand"></span>
          <span *ngIf="!workProfileItem.expand">
          <star-rating *ngIf="review.ratings[i] != 0" [disabled]="true" size="medium" [starType]="'svg'" [hoverEnabled]="true" [rating]="review.ratings[i]"></star-rating>
          </span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="reviewItemContainer">
        <mat-form-field class="full-width">
          <!--<mat-label>{{workProfileItem.title}}</mat-label>-->
          <textarea matInput #myText matTextareaAutosize [(ngModel)]="review.fields[i]"
                    name="reviewField{{i}}"></textarea>
        </mat-form-field>
        <div class="reviewRating">
          <star-rating [starType]="'svg'" matTooltip="{{review.ratings[i]}} out of 5" speed="slow" [rating]="review.ratings[i]" (starClickChange)="review.ratings[i] = $event.rating"></star-rating>
        </div>
        <div class="feedbacksContainer">
          <mat-tab-group class="feedbackTabs">
            <mat-tab *ngFor="let feedback of feedbacks; index as j;">
              <ng-template mat-tab-label>
                <p-avatar image="../../../assets/images/users/{{j+1}}.png" shape="circle"></p-avatar>
                <span class="revieweeName">{{feedback.reviewerName}}</span>
              </ng-template>
              <div class="feedbackTab">
                <div class="feedbackRating">
                  <star-rating [disabled]="true" size="medium" matTooltip="{{feedback.ratings[i]}} out of 5" *ngIf="reviewInfo.feedbacksWithRatings" [starType]="'svg'" [hoverEnabled]="true" [rating]="feedback.ratings[i]"></star-rating>

                </div>
                <div class="feedbackContent">{{feedback.fields[i]}}</div>
              </div>
              </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="button-row">
    <button mat-raised-button color="primary" (click)="submitReview('SUBMITTED')"
            >Submit
    </button>
    <button mat-raised-button color="accent" (click)="submitReview('DRAFT')"
            >Save Draft
    </button>
  </div>
</form>
