export class FeedbackListItem{

  id: string;

  revieweeId: string;
  revieweeName: string;
  reviewerId: string;
  reviewerName: string;
  reviewCycleId: string;
  reviewCycleName: string;
  workProfileName: string;
  expand = false;
  status: string;

  position: number;
}
export namespace FeedbackStatus{
  export enum status {
    CREATED = 'CREATED',
    ASSIGNED = 'ASSIGNED',
    DRAFT = 'DRAFT',
    REJECTED = 'REJECTED',
    SUBMITTED = 'SUBMITTED',
    APPROVED = 'APPROVED',
    CLOSED = 'CLOSED',
  }
}
