import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExpansionOverviewComponent} from './expansion-overview.component';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  imports: [BrowserModule, CommonModule, MatExpansionModule],
  declarations: [ExpansionOverviewComponent],
  exports: [ExpansionOverviewComponent],
  bootstrap: [ExpansionOverviewComponent]
})
export class ExpansionOverviewModule {

}
