export class Review {
  id: string;

  fields: string[] = [];
  ratings: number[] = [];
  workProfileFields: any[] = [];

  comments: string;
  status: string;
}
