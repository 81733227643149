<mat-card class="dashboard-card">
  <mat-card-header>
    <mat-card-title>Your performance</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="categoryRatingsAveraged">
      <ul class="list-group">
        <div class="list-group-item" *ngFor="let categoryRating of categoryRatingsAveraged">
          <span class="badge">{{categoryRating.value}}</span>
          {{categoryRating.key.name}}
        </div>
      </ul>
    </div>
    <div *ngIf="!categoryRatingsAveraged">
      There is no data available at this time.
    </div>
    <h2>TO DO: CHARTS</h2>

  </mat-card-content>
</mat-card>

