import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {UserListItem} from '../../../models/userListItem';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;

  users: UserListItem[] = [];
  displayedColumns: string[] = ['number', 'fullName', 'workProfileName', 'managerName', 'role'];
  dataSource;

  constructor(private readonly apiService: ApiService) {}

  getUsers() {
    this.apiService.getEmployeeList().subscribe(result => {
      this.users = result;

      this.users.forEach((value, index) => {
        value.number = index + 1;
      });

      this.dataSource = new MatTableDataSource(this.users);
      this.dataSource.sort = this.sort;
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getUsers();
  }

}
