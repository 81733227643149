<h3 class="title">{{reviewInfo.revieweeName}}'s review in {{reviewInfo.reviewCycleName}}</h3>
<p-avatar class="reviewee-avatar" image="../../../assets/images/users/1.png" size="xlarge"></p-avatar>
<div class="reviewContent">
  <div *ngFor="let wpField of review.workProfileFields; index as i">
    <p-fieldset legend="{{wpField.title}}" [toggleable]="false">
      <p>{{review.fields[i]}}
        <br>
        Rating: {{review.ratings[i]}} | Category: {{wpField?.category}}
      </p>
    </p-fieldset>
    <br><br>
  </div>
</div>
