import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbdTableSortable, NgbdSortableHeader} from './table-sortable.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [BrowserModule, CommonModule, NgbModule],
  declarations: [NgbdTableSortable, NgbdSortableHeader],
  exports: [NgbdTableSortable],
  bootstrap: [NgbdTableSortable]
})
export class NgbdTableSortableModule {

}
