import { Component, OnInit } from '@angular/core';
import {ReviewListItem} from '../../models/ReviewListItem';
import {ApiService} from '../../services/api.service';
import {ViewReviewComponent} from '../view-review/view-review.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-my-reviews',
  templateUrl: './my-reviews.component.html',
  styleUrls: ['./my-reviews.component.scss']
})
export class MyReviewsComponent implements OnInit {

  myReviews: ReviewListItem[] = [];
  myReviewsColumns: string[] = ['position', 'authorName', 'workProfileName', 'reviewCycleName', 'feedbacks', 'action'];

  constructor(private apiService: ApiService, public dialog: MatDialog, private authenticationService: AuthenticationService) { }

  viewReview(review: ReviewListItem) {
    this.dialog.open(ViewReviewComponent, {
      data: {
        review: review
      },
      width: '1400px',
      maxHeight: '95vh'
    });
  }

  ngOnInit(): void {
    this.apiService.getUserReviews(this.authenticationService.loggedInUser.id).subscribe(myReviews => {
      this.myReviews = myReviews;
      this.myReviews.forEach((value, index) => {
        value.position = index + 1;
      });
    });
  }

}
