import {ReviewListItem} from './ReviewListItem';

export class RcListItem {

  id: string;

  daysRemaining: string;
  completedReviews: number;
  totalFeedbacks: number;
  completedFeedbacks: number;

  feedbacksNeedingApproval: number;
  reviewsAwaitingCompilation: number;
  expand = true;

  compileReviewsByRCManager: boolean;
  ratingsFromFeedbacks: boolean;
  finalRatingRatio: number;
  feedbackApproval: boolean;
  approveFeedbacksByRCManager: boolean;
  feedbacksCanHangRC: boolean;

  name: string;
  status: string;
  startDate: string;
  endDate: string;

  managerName: string;

  reviews: ReviewListItem[];
}
