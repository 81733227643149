<h4>New Review Cycle</h4>

<form class="example-form" [formGroup]="this.newReviewCycleForm">
  <mat-form-field class="example-full-width">
    <mat-label>Review Cycle name</mat-label>
    <input matInput placeholder="Q3 2021 / Review Center project" formControlName="name">
  </mat-form-field>
  <mat-form-field appearance="fill" class="manager">
    <mat-label>Review Cycle manager</mat-label>
    <mat-select formControlName="manager">
      <mat-option *ngFor="let manager of managers" [value]="manager">
        {{manager.fullName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <div class="dates">
    <div class="date1">
      <mat-form-field appearance="fill">
        <mat-label>Start date</mat-label>
        <input matInput [matDatepicker]="startPicker" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="date2">
      <mat-form-field appearance="fill">
        <mat-label>End date</mat-label>
        <input matInput [matDatepicker]="endPicker" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <br>
  <mat-accordion>
    <mat-expansion-panel (opened)="advancedOptionsPannel = true"
                         (closed)="advancedOptionsPannel = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Advanced Settings
        </mat-panel-title>
        <mat-panel-description>
          {{advancedOptionsPannel ? 'Close advanced settings' : 'Open advanced settings'}}
        </mat-panel-description>
      </mat-expansion-panel-header>
<hr>
      <p>Who would you like to be the compiling manager?</p>
      <mat-radio-group formControlName="compileReviewsByRCManager"
                       matTooltip="This person will be responsible for compiling the final reviews.">
        <mat-radio-button [checked]="true" value="true">The review cycle manager</mat-radio-button>
        <mat-radio-button value="false">Individual reviewee's manager</mat-radio-button>
      </mat-radio-group>
      <br>
      <hr>

      <p>Would you like all reviewers to give ratings, or just the compiling manager?</p>
      <mat-radio-group formControlName="ratingsFromFeedbacks" matTooltip="Ratings can be given by only the respective compiling manager,
      or by all reviewers involved in the review cycle, as well.">
        <mat-radio-button value="true">All reviewers</mat-radio-button>
        <mat-radio-button [checked]="true" value="false">Only compiling manager</mat-radio-button>
      </mat-radio-group>
      <br>

      <div *ngIf="newReviewCycleForm.controls['ratingsFromFeedbacks'].value === 'true'">
        <span>Manager Rating Ratio: {{finalRatingRatio.value}}%</span>
        <mat-slider formControlName="finalRatingRatio"
                    #finalRatingRatio
                    thumbLabel
                    [displayWith]="formatRatingRatioLabel"
                    tickInterval="1"
                    value="50"
                    min="0"
                    max="100" matTooltip="Determines how much the compiling manager's rating will count in the final rating asessment.
        This will apply only if the previous configuration is set to 'all reviewers'."></mat-slider>
        <br>

      </div>
      <hr>

      <p>Do you want feedbacks to be approved?</p>
      <mat-radio-group formControlName="feedbackApproval"
                       matTooltip="Select 'yes' if you want feedbacks to first be approved by a manager before they get in the review compiling process. Otherwise, feedbacks will not have to be approved before they reach the final compiling stage.">
        <mat-radio-button value="true">Yes</mat-radio-button>
        <mat-radio-button [checked]="true" value="false">No</mat-radio-button>
      </mat-radio-group>
      <br>
      <hr>


      <p>Who would you like to approve the feedbacks?</p>
      <mat-radio-group formControlName="approveFeedbacksByRCManager" matTooltip="If the compiling manager is selected, he will approve all feedbacks in the review cycle.
      Otherwise, each reviewee's manager is responsible of approving the feedbacks given for that specific reviewee.
      This will only apply if feedback approval is enabled.">
        <mat-radio-button [checked]="true" value="true">The review cycle manager</mat-radio-button>
        <mat-radio-button value="false">Individual reviewee's manager</mat-radio-button>
      </mat-radio-group>
      <br>
      <hr>


      <p>Allow unsubmitted/unapproved feedback to prevent closing the review cycle?</p>
      <mat-radio-group formControlName="feedbacksCanHangRC" matTooltip="The review cycle closes automatically when reaching its end date; it remains open only if reviews have not been yet compiled.
      If you select 'yes', then review cycle will remain open until all feedbacks are submitted and/or approved. This could cause delays in submitting the final reviews.
      Selecting 'no' will auto-submit all pending feedback 48h prior to the review cycle end date.">
        <mat-radio-button [checked]="true" value="true">Yes</mat-radio-button>
        <mat-radio-button value="false">No</mat-radio-button>
      </mat-radio-group>
    </mat-expansion-panel>
  </mat-accordion>
  <br>

  <div class="button-row">
    <button mat-stroked-button color="primary" (click)="createReviewCycle()" [disabled]="!newReviewCycleForm.valid">
      Create
    </button>
    <button mat-stroked-button color="accent" [mat-dialog-close]="null">Cancel</button>
  </div>
</form>
